import { Routes } from "@angular/router";
import { authenticationGuard } from "./features/auth/guards/auth.guard";

export const routes: Routes = [
    {
        path: "",
        redirectTo: "home",
        pathMatch: "full"
    },
    {
        path: "home",
        canActivate: [authenticationGuard()],
        loadComponent: () =>
            import("./pages/home/home.component").then(
                (page) => page.HomeComponent
            )
    },
    {
        path: "faqs",
        canActivate: [authenticationGuard()],
        loadComponent: () =>
            import("./pages/faqs/faqs.component").then(
                (page) => page.FaqsComponent
            )
    },
    {
        canActivate: [authenticationGuard()],
        path: "report",
        children: [
            {
                path: ":id",
                children: [
                    {
                        path: "**",
                        loadComponent: () =>
                            import("./pages/report/report.component").then(
                                (page) => page.ReportComponent
                            )
                    }
                ]
            }
        ]
    },
    {
        path: "unauthorized",
        loadComponent: () =>
            import("./pages/unauthorized/unauthorized.component").then(
                (page) => page.UnauthorizedComponent
            )
    },
    {
        path: "404",
        loadComponent: () =>
            import("./pages/not-found/not-found.component").then(
                (page) => page.NotFoundComponent
            )
    }
];
