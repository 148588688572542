import { LogLevel, PassedInitialConfig } from "angular-auth-oidc-client";
import { environment } from "../../../environments/environment";

export const authConfig: PassedInitialConfig = {
    config: {
        authority: environment.authUrl,
        redirectUrl: window.origin,
        postLogoutRedirectUri: window.origin,
        clientId: environment.authClientId,
        scope: "openid username email", // 'openid profile offline_access ' + your scopes
        responseType: "code",
        silentRenew: false,
        useRefreshToken: false,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
        secureRoutes: ["/ddp/"]
    }
};
