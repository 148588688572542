import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map, switchMap, take, tap } from "rxjs";
import { AuthService } from "../services/auth.service";

export function authenticationGuard(): CanActivateFn {
    return () => {
        const oidcSecurityService = inject(OidcSecurityService);
        const auth = inject(AuthService);
        const router = inject(Router);
        return oidcSecurityService.isAuthenticated$.pipe(
            take(1),
            switchMap(() => oidcSecurityService.checkAuth()),
            map(({ isAuthenticated }) => {
                if (isAuthenticated) return true && !auth.unauthorizedFromApi();
                else {
                    oidcSecurityService.authorize();
                    return false;
                }
            }),
            tap((res) => {
                if (!res)
                    router.navigate(["/", "unauthorized"], {
                        replaceUrl: true
                    });
            })
        );
    };
}
