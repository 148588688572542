import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { map, switchMap, take } from "rxjs";

export function authenticationGuard(): CanActivateFn {
    return () => {
        const oidcSecurityService = inject(OidcSecurityService);

        return oidcSecurityService.isAuthenticated$.pipe(
            take(1),
            switchMap(() => oidcSecurityService.checkAuth()),
            map(({ isAuthenticated }) => {
                if (isAuthenticated) return true;
                else {
                    oidcSecurityService.authorize();
                    return false;
                }
            })
        );
    };
}
