import {
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";

export function mockUserInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(window.origin) || req.url.startsWith("/")) {
        const newReq = req.clone({
            headers:
                environment.mockUser && !req.headers.get("userId")
                    ? req.headers.append("userId", environment.userId)
                    : req.headers
        });
        return next(newReq);
    } else {
        return next(req);
    }
}
