import {
    ApplicationConfig,
    importProvidersFrom,
    provideZoneChangeDetection
} from "@angular/core";
import { provideRouter, withComponentInputBinding } from "@angular/router";

import { routes } from "./app.routes";
import { ApiModule, Configuration } from "./api/v1";
import { environment } from "../environments/environment";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { mockUserInterceptor } from "./features/auth/interceptors/mockUserInterceptor";
import { authConfig } from "./features/auth/auth.config";
import { authInterceptor, provideAuth } from "angular-auth-oidc-client";
import { unauthorizedInterceptor } from "./features/auth/interceptors/unauthorizedInterceptor";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(
            withInterceptors(
                environment.mockUser
                    ? [
                          mockUserInterceptor,
                          authInterceptor(),
                          unauthorizedInterceptor
                      ]
                    : [authInterceptor(), unauthorizedInterceptor]
            )
        ),
        importProvidersFrom(
            ApiModule.forRoot(
                () =>
                    new Configuration({
                        basePath: environment.apiPath
                    })
            )
        ),
        provideAuth(authConfig)
    ]
};
