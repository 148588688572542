import { inject, Injectable, signal } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    unauthorizedFromApi = signal<boolean>(false);
    oidcSecurityService = inject(OidcSecurityService);

    constructor() {
        // this.oidcSecurityService.isAuthenticated$.subscribe((res) => {
        //     if (res.isAuthenticated) this.unauthorizedFromApi.set(false);
        //});
    }
}
