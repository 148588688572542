import { Component, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
    NavigationEnd,
    Router,
    RouterLink,
    RouterLinkActive,
    RouterOutlet
} from "@angular/router";
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs";
import { ReportFiltersService } from "./features/powerbi/services/report-filters.service";
import { ReportsService } from "./features/powerbi/services/reports.service";

@Component({
    selector: "ddp-root",
    standalone: true,
    imports: [RouterOutlet, RouterLink, RouterLinkActive],
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss"
})
export class AppComponent {
    router = inject(Router);
    reportFilterService = inject(ReportFiltersService);
    reports = inject(ReportsService).reports;
    insideReport = toSignal(
        this.router.events.pipe(
            filter((ev) => ev instanceof NavigationEnd),
            map((ev) => {
                return this.router.isActive("report", {
                    paths: "subset",
                    queryParams: "subset",
                    fragment: "ignored",
                    matrixParams: "ignored"
                });
            }),
            distinctUntilChanged(),
            shareReplay(1)
        )
    );
}
