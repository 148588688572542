import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest
} from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

export function unauthorizedInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    const router = inject(Router);
    const auth = inject(AuthService);
    return next(req).pipe(
        catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                auth.unauthorizedFromApi.set(true);
                router.navigate(["/", "unauthorized"], { replaceUrl: true });
            }
            return throwError(() => err);
        })
    );
}
