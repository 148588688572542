<header>
    <div class="top-bar">
        <a
            routerLink="/"
            class="logo"
        >
            <h1>
                <img src="/logo_title.svg" />
            </h1>
        </a>
        <div class="actions">
            <a
                class="yellow"
                routerLink="/faqs"
            >
                ?
            </a>
            @if (insideReport()) {
                <button><img src="/excel.svg" /></button>
                <button (click)="reportFilterService.openFilters()">
                    <img src="/menu.svg" />
                </button>
            }
            <a
                href="/manual.svg"
                download
                ><img src="/manual.svg"
            /></a>
        </div>
    </div>
    @if (insideReport()) {
        <ul class="navigation">
            @for (report of reports(); track report.reportId) {
                <a
                    [routerLink]="['/', 'report', report.reportId]"
                    routerLinkActive="active"
                >
                    <li>
                        {{ report.description }}
                    </li>
                </a>
            }
        </ul>
    }
</header>
<main>
    <div
        class="content"
        [class.report]="insideReport()"
    >
        <router-outlet />
    </div>
    @if (!insideReport()) {
        <footer>
            <div class="logo"><img src="/logo.svg" /></div>
            <div class="footer-section">
                <p>
                    The information, data, and content presented in this
                    dashboard are provided for internal use purposes only.
                    Unauthorized access to, use, or disclosure of data in this
                    dashboard is strictly prohibited.
                </p>
                <p>©2024 Maserati. All rights reserved</p>
            </div>
            <div class="footer-section">
                <p>
                    Our dedicated support team is here to help you. Contact us
                    at dataportal&#64;maserati.com.
                </p>
            </div>
        </footer>
    }
</main>
